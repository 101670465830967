import React from 'react';
import './LandingPage.css';
import logo from '../img/jibe-white.png';

const LandingPage = () => {
  return (
    <div className="landing-page">
      <div className="logo-container">
        <img src={logo} alt="Logo JIBE" />
      </div>
      <h1 className="coming-soon-text">Something might be coming soon...</h1>
    </div>
  );
};

export default LandingPage;
